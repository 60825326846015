import React, { useState, useEffect } from "react";
import "../assets/css/music.css";

const SpotifyPreview = ({ uri, size = 400 }) => {
  const [width, setWidth] = useState(size);
  const [height, setHeight] = useState(size);

  useEffect(() => {
    // Update width and height when the component mounts
    setWidth(size);
    setHeight(size);

    // Add event listener to update width and height on window resize
    const handleResize = () => {
      // Update width and height based on your conditions
      // For example, update size on screens smaller than 600px
      if (window.innerWidth < 786) {
        setWidth(300);
        setHeight(160);
      } else {
        // Default size for larger screens
        setWidth(size);
        setHeight(size);
      }
    };

    // Attach the event listener
    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [size]);

  return (
    <iframe
      title="Spotify Preview"
      src={`https://open.spotify.com/embed/${uri}`}
      width={width}
      height={height}
      allowtransparency="true"
      allow="encrypted-media"
      style={{ border: "none" }}
    />
  );
};

export default function Music() {
  return (
    <div className="music-container">
      <h3 style={{ marginBottom: "30px" }}>Music & Media</h3>
      <div className="spotify-card-shelf">
        <SpotifyPreview uri="playlist/0FIt22kiOdN2IFdjfi4Zdi?si=gGqBtqwKQ0q7Mp3hAQfEZA" />
        <SpotifyPreview uri="playlist/7zA8aXJdo7IBzlBMAoWHzA?si=_6Jxp7UMSM6MYbGPkuT2iQ" />
        <SpotifyPreview uri="playlist/0x5TjtESLLEuajn8d92zmr?si=TDYxwvmDSBWBYTIiU_SooQ" />
        <SpotifyPreview uri="playlist/4DjvB6q6DqRAiE6akVnE5e?si=N_vVaR7LQaG_yi70efKY-Q" />
        <SpotifyPreview uri="playlist/5BpjPMp7YdoyXAiskzPNm5?si=7q7MWAzjTYK3d5WYTHF4nw" />
        <SpotifyPreview uri="playlist/3WkD0SnPE1ps2IbVzIoZxt?si=qwhqW8B3S82sCacutc9GXg" />
        <SpotifyPreview uri="playlist/3vaQQ76zsBrMha6YFMwI0F?si=pijZY1LOS4yze2UaG7EY9Q" />
        <SpotifyPreview uri="playlist/43PvvoRcOXezFOSLS5tnKv?si=qecQTWUXSD6MlQCCPct9YA" />
        <SpotifyPreview uri="playlist/0b4dsa9NGt5r9Gnr6Iss9F?si=T3cU2LceSFSMCVTah4GtGg" />
        <SpotifyPreview uri="playlist/5LOGHinf9iqfg9aovd98Qj?si=p-4DEKN4TvmxWu0MV8VjIw" />
      </div>
    </div>
  );
}
